import { register } from "swiper/element/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import api from "../../Services/api";
import { useState, useEffect } from "react";
import { CgExport } from "react-icons/cg";

const ExportCSVButton = ({ exportData }) => {
	
    function convertArrayOfObjectsToCSV(array) {
        let result;
        
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(exportData[0]);
        
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
        
            result += item[key];
            
            ctr++;
            });
            result += lineDelimiter;
        });
        
        return result;
    }
    
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        
        const filename = 'export.csv';
        
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

	return (
        <button className="bg-white hover:bg-[#EFCA04] ml-auto text-black font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer" onClick={() => downloadCSV(exportData)}>
            <CgExport className="w-4 h-4 mr-2" size={40} />
            <span>Export CSV</span>
        </button>
	);
};

export default ExportCSVButton;
