import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { GridActionsCellItem } from "@mui/x-data-grid";

import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, MyDataGrid } from "./styles";
import { BsEyeFill, BsArrowRight, BsArrowLeft } from "react-icons/bs";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";
import DataTable from 'react-data-table-component';
import { CgExport } from "react-icons/cg";
import CreatableSelect from "react-select/creatable";
import ExportCSVButton from "../../../Components/ExportCSVButton";

export default function Pacientes() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [repositoriesData, setRepositoriesData] = useState([]);
  const [infoPagina, setInfoPagina] = useState(
      {
        has_next: false,
        has_previous: false,
        per_page: 0,
        page: 1,
        total: 0,
      }
    );
  const [quantidadeExibidaNaTabela, setQuantidadeExibidaNaTabela] = useState({label: "20", value: 20});

    const dropdownCustomStyles = {
      control: (provided, state) => ({
        ...provided,
        width: "10vw",
        minHeight: "30px",
        borderRadius: "10px",
        backgroundColor: "#fafafa",
        fontSize: "18px",
        color: "black",
        border: "1px solid #d5d5d5",
      }),
      option: (provided, state) => ({
        ...provided,
        color: "black", // Cor do texto das opções
      }),
    };

    const opcoesQuantidadeExibidos = [
      {label: "5", value: 5},
      {label: "10", value: 10},
      {label: "15", value: 15},
      {label: "20", value: 20}
    ];

const colunasTabela = [
    {
      name: 'Nome',
      selector: row => row.nome,
    },
    {
      name: 'CPF',
      selector: row => row.cpf,
    },
    {
      name: 'Telefone',
      selector: row => row.telefone,
    },
    {
      name: '',
      maxWidth: '40px',
      selector: row => 
      <BsEyeFill
      className="cursor-pointer" 
      onClick={() => {
        history.push(`/pacientes/${row.id}`);
      }} size={20} color="#7A99E3" 
      />,
    },
  ];

  const fetchRepositoriesData = async (proximaPagina = false, ultimaPagina = false, quantidadeParaExibir = quantidadeExibidaNaTabela) => {
      setLoading(true);
      setErrorRepositories(null);

      try {
        let modifier = 0;
        if (proximaPagina) {
          modifier = 1;
        }
        if (ultimaPagina) {
          modifier = -1;
        }
        const response = await api.get(`/pacientes/listar?page=${infoPagina.page + modifier}&per_page=${quantidadeParaExibir.value}`);
        const repositories = response.data;
        console.log(repositories);
        setRepositoriesData(repositories.pacientes);
        setInfoPagina(
          {
            has_next: response.data.has_next,
            has_previous: response.data.has_previous,
            per_page: response.data.per_page,
            page: response.data.page,
            total: response.data.total,
          }
        );
      } catch (error) {
        setErrorRepositories("Ocorreu um erro ao carregar a página");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const proximaPagina = async (proxima) => {
      console.log("foi de next");
      if (proxima) {
        if (infoPagina.has_next) {
          fetchRepositoriesData(true, false);
        }
      }
      else {
        if (infoPagina.has_previous) {
          fetchRepositoriesData(false, true);
        }
      }
    };

    const mudarQuantidadeExibida = async(quantidade) => {
      setQuantidadeExibidaNaTabela(quantidade);
      fetchRepositoriesData(false, false, quantidade);
    }

  useEffect(() => {
    fetchRepositoriesData();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainerDiv>
          <SidebarMenu />
          <Background>
            <RightDiv>
              <PageTitle>Usuários</PageTitle>
              <ErrorMsg>{errorRepositories}</ErrorMsg>
              <ErrorBtn onClick={(event) => refresh(event)}>
                Tentar novamente
              </ErrorBtn>
            </RightDiv>
          </Background>
        </PageContainerDiv>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainerDiv>
          <SidebarMenu />
          <Background>
            <RightDiv>
              <PageTitle>Usuários</PageTitle>
              <ReactLoading
                type="spin"
                color="#B2EBF2"
                height={"30px"}
                width={"30px"}
              />
            </RightDiv>
          </Background>
        </PageContainerDiv>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainerDiv>
        <SidebarMenu />
        <Background>
          <RightDiv>
            <PageTitle>Usuários</PageTitle>
            {/* <Tabela rows={repositoriesData} columns={columns} /> */}
            <div className="flex flex-col w-full space-y-2">
                <div className="flex flex-row w-full justify-between items-center">
                  <p className="text-white font-bold pr-2">Exibir:</p>
                  <CreatableSelect
                    options={opcoesQuantidadeExibidos}
                    required
                    value={quantidadeExibidaNaTabela}
                    onChange={(e) => {
                      e.value > 20 ? mudarQuantidadeExibida(opcoesQuantidadeExibidos.at(-1)) : mudarQuantidadeExibida(e);
                    }}
                    styles={dropdownCustomStyles}
                    placeholder={"Selecione a quantidade que será exibida na tabela"}
                  />
                  <ExportCSVButton exportData={repositoriesData} />
                </div>
                <div className="w-full max-h-96 overflow-x-auto overflow-y-visible">
                  <DataTable
                    columns={colunasTabela}
                    data={repositoriesData}
                    responsive
                    customStyles={{
                      headCells: {
                        style: {
                          backgroundColor: "#EFCA04",
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>

                <div className="flex flex-row w-full justify-between items-center">
                  <BsArrowLeft 
                  className={infoPagina.has_previous ? "cursor-pointer" : "cursor-not-allowed"}
                  onClick={() => proximaPagina(false)} 
                  size={50} 
                  color={infoPagina.has_previous ? "#EFCA04" : "#ebe0a7"} />
                  <p className=" text-[#EFCA04]">
                    {infoPagina.page + "/" + Math.ceil(infoPagina.total/infoPagina.per_page)}
                  </p>
                  <BsArrowRight 
                  className={infoPagina.has_next ? "cursor-pointer" : "cursor-not-allowed"}
                  onClick={() => proximaPagina(true)} 
                  size={50} 
                  color={infoPagina.has_next ? "#EFCA04" : "#ebe0a7"} />
                </div>
              </div>
          </RightDiv>
        </Background>
      </PageContainerDiv>
    </Page>
  );
}
