import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

import Cadastro from "../Pages/SignedOut/Cadastro";
import PasswordReset from "../Pages/SignedOut/RedefinirSenha";
import ForgotPassword from "../Pages/SignedOut/EsqueciMinhaSenha";
import Login from "../Pages/SignedOut/Login";

import Cadastrar from "../Pages/SignedIn/Cadastrar";
import CadastrarVendedor from "../Pages/SignedIn/CadastrarVendedor";
import CadastrarMedico from "../Pages/SignedIn/CadastrarMedico";

import Pacientes from "../Pages/SignedIn/Pacientes";
import PacienteUnico from "../Pages/SignedIn/PacienteUnico";

import Prestadores from "../Pages/SignedIn/Prestadores";
import PrestadorUnico from "../Pages/SignedIn/PrestadorUnico";

import Creditos from "../Pages/SignedIn/Creditos";
import Transferencia from "../Pages/SignedIn/Transferencia";

import Metricas from "../Pages/SignedIn/MetricasAcessos";

import Pendentes from "../Pages/SignedIn/Pendentes";
import PendenteUnico from "../Pages/SignedIn/PendenteUnico";

import Permissoes from "../Pages/SignedIn/Permissoes";
import CodigoEmail from "../Pages/SignedOut/CodigoEmail";

import ValorCredito from "../Pages/SignedIn/ValorCredito";

import Perfil from "../Pages/SignedIn/Perfil";
import RedefinicaoSenha from "../Pages/SignedIn/RedefinicaoDeSenha";
import Pagamento from "../Pages/SignedIn/Pagamento";
import PagamentoFeito from "../Pages/SignedIn/PagamentoFeito";
import Consultas from "../Pages/SignedIn/Consultas";
import Procedimentos from "../Pages/SignedIn/Procedimentos";
import ConsultaUnica from "../Pages/SignedIn/ConsultaUnica";
import PagamentosAdministrador from "../Pages/SignedIn/PagamentosAdministrador";
import PagamentoUnico from "../Pages/SignedIn/PagamentoUnico";
import Pagar from "../Pages/SignedIn/Pagar";
import RepassePagamento from "../Pages/SignedIn/RepassePagamento";
import PerfilAdministrador from "../Pages/SignedIn/PerfilAdministrador";
import LandingPage from "../Pages/SignedOut/LandingPage";
import MetricasAcessos from "../Pages/SignedIn/MetricasAcessos";
import MetricasUsers from "../Pages/SignedIn/MetricasUsers";
import MetricasCreditos from "../Pages/SignedIn/MetricasCreditos";
import ProcedimentoUnico from "../Pages/SignedIn/ProcedimentoUnico";

export default function Routes() {
	const { isAuthorized, user, token } = useAuth();
	console.log(token, isAuthorized());

	const PrestadorRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					token && isAuthorized() === "medico" ? <Component {...props} /> : <Redirect to="/login" />
				}
			/>
		);
	};

	const AdminRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					token && isAuthorized() === "admin" ? <Component {...props} /> : <Redirect to="/login" />
				}
			/>
		);
	};

	const ClinicaRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					token && isAuthorized() === "clinica" ? (
						<Component {...props} />
					) : (
						<Redirect to="/login" />
					)
				}
			/>
		);
	};

	const VendedorRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					token && isAuthorized() === "vendedor" ? (
						<Component {...props} />
					) : (
						<Redirect to="/login" />
					)
				}
			/>
		);
	};

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/" component={LandingPage} />
				{/*<Route exact path="/cadastro" component={Cadastro} />*/}
				<Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
				<Route path="/redefinir-senha" component={PasswordReset} />
				<Route path="/resetar-senha" component={CodigoEmail} />

				{user.tipo === "admin" ? (
					<>
						<AdminRoute exact path="/perfil" component={PerfilAdministrador} />
						<AdminRoute exact path="/cadastrar" component={Cadastrar} />
						<AdminRoute exact path="/cadastrar/vendedor" component={CadastrarVendedor} />
						<AdminRoute exact path="/cadastrar/medico" component={CadastrarMedico} />
						<AdminRoute exact path="/pacientes" component={Pacientes} />
						<AdminRoute path="/pacientes/:id" component={PacienteUnico} />
						<AdminRoute exact path="/prestadores" component={Prestadores} />
						<AdminRoute path="/prestadores/:id" component={PrestadorUnico} />
						<AdminRoute exact path="/pendentes" component={Pendentes} />
						<AdminRoute path="/pendentes/:id" component={PendenteUnico} />
						<AdminRoute exact path="/creditos" component={Creditos} />
						<AdminRoute exact path="/metricas" component={MetricasAcessos} />
						<AdminRoute exact path="/metricas/acessos" component={MetricasAcessos} />
						<AdminRoute exact path="/metricas/creditos" component={MetricasCreditos} />
						<AdminRoute exact path="/metricas/users" component={MetricasUsers} />
						<AdminRoute exact path="/creditos/transferencia" component={Transferencia} />
						<AdminRoute exact path="/permissoes" component={Permissoes} />
						<AdminRoute exact path="/valor-crédito" component={ValorCredito} />
						<AdminRoute exact path="/repasse-pagamento" component={RepassePagamento} />
						<AdminRoute
							exact
							path="/pagamentos-administrador"
							component={PagamentosAdministrador}
						/>
						<AdminRoute exact path="/pagamentos-administrador/:id" component={PagamentoUnico} />
						<AdminRoute exact path="/pagamentos-administrador/:id/pagar" component={Pagar} />
						<AdminRoute exact path="/redefinicao-de-Senha" component={RedefinicaoSenha} />
					</>
				) : user.tipo === "medico" ? (
					<>
						<PrestadorRoute exact path="/perfil" component={Perfil} />
						<PrestadorRoute path="/pagamento" component={Pagamento} />
						<PrestadorRoute exact path="/pagamentofeito" component={PagamentoFeito} />
						<PrestadorRoute exact path="/consultas" component={Consultas} />
						<PrestadorRoute exact path="/consultas/:id" component={ConsultaUnica} />
						<PrestadorRoute exact path="/procedimentos" component={Procedimentos} />
						<PrestadorRoute exact path="/procedimentos/:id" component={ProcedimentoUnico} />

						<PrestadorRoute exact path="/redefinicao-de-Senha" component={RedefinicaoSenha} />
					</>
				) : user.tipo === "vendedor" ? (
					<>
						<VendedorRoute exact path="/perfil" component={PerfilAdministrador} />
						<VendedorRoute path="/pagamento" component={Pagamento} />
						<VendedorRoute exact path="/pagamentofeito" component={PagamentoFeito} />
						<VendedorRoute exact path="/consultas" component={Consultas} />
						<VendedorRoute exact path="/consultas/:id" component={ConsultaUnica} />

						<VendedorRoute exact path="/procedimentos" component={Procedimentos} />
						<VendedorRoute exact path="/procedimentos/:id" component={ProcedimentoUnico} />

						<VendedorRoute exact path="/redefinicao-de-Senha" component={RedefinicaoSenha} />
						<VendedorRoute exact path="/cadastrar" component={Cadastrar} />
						<VendedorRoute exact path="/cadastrar/vendedor" component={CadastrarVendedor} />

						<VendedorRoute exact path="/cadastrar/medico" component={CadastrarMedico} />
					</>
				) : (
					<></>
				)}
			</Switch>
		</BrowserRouter>
	);
}
