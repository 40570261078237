import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Link, useParams } from "react-router-dom";
import api from "../../../Services/api";
import axios from "axios";
import { useAuth } from "../../../Hooks/useAuth";
import MainButton from "../../../Components/MainButton";
import {
	Content,
	Info,
	InfoBlock,
	InfoTitle,
	PacientInfo,
	PageTitle,
	Redefinir,
	PacientRow,
	MissingInfo,
	RightDiv,
	PageContainerDiv,
	Background,
	InputEdit,
} from "./styles";
import img from "./assets/back.png";
import { toast, ToastContainer } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import ReactLoading from "react-loading";

function Perfil() {
	const { user } = useAuth();
	const { id } = useParams();
	const [dados, setDados] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [nome, setNome] = useState("");
	const [email, setEmail] = useState("");
	const [cnpj, setCnpj] = useState("");
	const [cpf, setCpf] = useState("");
	const [endereco, setEndereco] = useState("");
	const [bairro, setBairro] = useState("");
	const [cidade, setCidade] = useState("");
	const [estado, setEstado] = useState("");
	const [cep, setCep] = useState("");
	const [chavepix, setChavepix] = useState("");
	const [tipochave, setTipochave] = useState("");
	const [twitterUrl, setTwitterUrl] = useState("");
	const [instagramUrl, setInstagramUrl] = useState("");
	const [facebookUrl, setFacebookUrl] = useState("");
	const [creditosConsulta, setCreditosConsulta] = useState("");
	const [especialidades, setEspecialidades] = useState("");
	const [loadingEditar, setLoadingEditar] = useState(false);

	const { updateUserData } = useAuth();

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await api.get(`/user/${user.id}`);
				console.log("resposta:", response.data);
				setDados(response.data);
				setNome(response.data.nome);
				setEmail(response.data.email);
				setCpf(response.data.medico.cpf);
				setCnpj(response.data.medico.cnpj);
				setChavepix(response.data.dados_bancarios.chave_pix);
				setTipochave(response.data.dados_bancarios.tipo_chave);
				setEndereco(response.data.medico.endereco);
				setBairro(response.data.bairro.nome_bairro);
				setCidade(response.data.cidade.nome_cidade);
				setEstado(response.data.estado.nome_estado);
				setTwitterUrl(response.data.medico.twitter_url);
				setInstagramUrl(response.data.medico.instagram_url);
				setFacebookUrl(response.data.medico.facebook_url);
				setCreditosConsulta(response.data.creditos_consulta);
				setEspecialidades(response.data.medico.especialidades);
			} catch (error) {
				console.log(error, "Erro no perfil");
			}
		};

		fetchUserData();
	}, [user.id]);

	const handleCepBlur = async (event) => {
		const cepInput = event.target.value.replace(/\D/g, "");
		console.log("cep", event, "cep", cep, cepInput);

		if (cepInput.length === 8) {
			try {
				const response = await axios.get(`https://viacep.com.br/ws/${cepInput}/json/`);
				const { data } = response;
				if (!data.erro) {
					setCep(cepInput);
					setEndereco(data.logradouro);
					setBairro(data.bairro);
					setCidade(data.localidade);
					setEstado(data.uf);
				} else {
					// Limpar os outros campos se o CEP for inválido ou não for encontrado
					setCep(cep);
					setEndereco(endereco);
					setBairro(bairro);
					setCidade(cidade);
					setEstado(estado);

					console.log("CEP inválido ou não encontrado");
				}
			} catch (error) {
				// Tratar erros de requisição
				console.log("Erro ao buscar CEP", error);
			}
		} else {
			// Limpar os outros campos caso o CEP não tenha 8 dígitos
			setCep(cep);
			setEndereco(endereco);
			setBairro(bairro);
			setCidade(cidade);
			setEstado(estado);
		}
	};

	const handleEdit = async () => {
		setLoadingEditar(true);
		try {
			const infoAlteradas = {
				nome,
				email,
				bairro,
				cidade,
				estado,
				dados_bancarios: {
					chave_pix: chavepix ? chavepix : "",
					tipo_chave: tipochave ? tipochave : "",
				},
				medico: {
					endereco: endereco ? endereco : "",
					twitter_url: twitterUrl ? twitterUrl : "",
					instagram_url: instagramUrl ? instagramUrl : "",
					facebook_url: facebookUrl ? facebookUrl : "",
					creditos_consulta: creditosConsulta ? creditosConsulta : 0,
					especialidades: especialidades.map((value, key) => {
						return { nome: value.nome };
					})
						? especialidades
						: "",
				},
			};
			const response = await api.patch(`/user/${user.id}`, infoAlteradas);

			await updateUserData(response.data);
			setDados(response.data);
			console.log("dados", response.data);
			setEditMode(false);
			toast.success("Perfil editado com sucesso");
		} catch (error) {
			console.log(error, "Erro ao editar o perfil");
			toast.error("Erro ao editar perfil");
		}
		setLoadingEditar(false);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: "16vw",
			minHeight: "30px",
			borderRadius: "10px",
			backgroundColor: "#fafafa",
			fontSize: "18px",
			color: "black",
			border: "1px solid #d5d5d5",
		}),
		option: (provided, state) => ({
			...provided,
			color: "black", // Cor do texto das opções
		}),
	};
	const customStyles1 = {
		control: (provided, state) => ({
			...provided,
			width: "16vw",
			minHeight: "30px",
			borderRadius: "10px",
			backgroundColor: "#fafafa",
			fontSize: "16px",
			color: "black",
			border: "1px solid #d5d5d5",
		}),
		option: (provided, state) => ({
			...provided,
			color: "black", // Cor do texto das opções
		}),
	};

	const specialtiesList = [
		"Acupuntura",
		"Agente comunitário de saúde",
		"Alergia e imunologia",
		"Anatomopatologista",
		"Anestesiologista",
		"Angiologia",
		"Assistente social",
		"Atendente de consultório dentário",
		"Auxiliar de enfermagem",
		"Auxiliar de farmácia de manipulação",
		"Auxiliar de laboratório de análises clínicas",
		"Auxiliar de radiologia (revelação fotográfica)",
		"Auxiliar em saúde bucal da estratégia de saúde da família",
		"Auxiliar técnico em patologia clínica",
		"Biólogo",
		"Biomédico",
		"Cancerologista cirúrgico (oncologista cirúrgico)",
		"Cardiologia",
		"Cirurgia cardiovascular",
		"Cirurgia da mão",
		"Cirurgia de cabeça e pescoço",
		"Cirurgia do aparelho digestivo",
		"Cirurgia geral",
		"Cirurgia pediátrica",
		"Cirurgia plástica",
		"Cirurgia torácica",
		"Cirurgia vascular",
		"Cirurgião dentista - clínico geral",
		"Cirurgião dentista - dentística",
		"Cirurgião dentista - endodontista",
		"Cirurgião dentista - estratégia saúde da família",
		"Cirurgião dentista - ortopedista e ortodontista",
		"Cirurgião dentista - patologista bucal",
		"Cirurgião dentista - radiologista",
		"Cirurgião dentista - traumatologista bucomaxilofacial",
		"Cirurgião dentista de saúde coletiva",
		"Clínica médica",
		"Clínico geral",
		"Curso de dependência química",
		"Dermatologia",
		"Diretor de serviços de saúde",
		"Educador físico",
		"Endocrinologia e metabologia",
		"Endoscopista",
		"Enfermeiro",
		"Farmacêutico",
		"Farmacêutico bioquímico",
		"Fisiatra",
		"Fisioterapeuta esportivo",
		"Fisioterapeuta geral",
		"Fonoaudiólogo",
		"Gastroenterologia",
		"Geneticista (médico)",
		"Geriatra",
		"Ginecologia e obstetrícia",
		"Hematologia",
		"Hemoterapeuta",
		"Hiperbarista",
		"Infectologista",
		"Instrumentador cirúrgico",
		"Intensivista",
		"Legista",
		"Massagista",
		"Mastologista",
		"Medicina estética",
		"Medicina nuclear",
		"Médico coloproctologista",
		"Médico de família e comunidade",
		"Médico do trabalho",
		"Médico em medicina de tráfego",
		"Médico em medicina preventiva e social",
		"Médico estratégia saúde da família",
		"Médico homeopata",
		"Médico patologista clínico / medicina laboratorial",
		"Médico residente",
		"Médico veterinário",
		"Musicoterapeuta",
		"Nefrologia",
		"Neurocirurgião",
		"Neurofisiologia clínica",
		"Neurologia",
		"Neuropsicólogo",
		"Nutricionista",
		"Nutrologia",
		"Odontologista",
		"Oftalmologista",
		"Oncologia",
		"Ortodontia e ortopedia facial",
		"Ortopedia e traumatologia",
		"Ortoptista",
		"Otorrinolaringologista",
		"Outras",
		"Patologista",
		"Pediatria",
		"Pneumologia",
		"Podologia",
		"Preparador físico",
		"Psicologia clínica",
		"Psicopedagogo",
		"Psiquiatria",
		"Radiologista / imagenologista",
		"Radioterapeuta",
		"Reumatologia",
		"Sanitarista",
		"Terapeuta",
		"Terapeuta ocupacional",
		"Trabalhador de serviços de limpeza e conservação de áreas públicas",
		"Técnico de enfermagem",
		"Técnico de enfermagem do trabalho",
		"Técnico de laboratório de análises físico-químicas (materiais de construção)",
		"Técnico em acupuntura",
		"Técnico em fotônica",
		"Técnico em higiene dental",
		"Técnico em patologia clínica",
		"Técnico em radiologia e imagenologia",
		"Urologia",
		"Visitador sanitário",
	];

	const formattedList = specialtiesList.map((especialidades) => {
		return { label: especialidades, value: especialidades };
	});

	const chavesList = [
		{ label: "CPF", value: "CPF" },
		{ label: "E-mail", value: "email" },
		{ label: "Telefone", value: "telefone" },
		{ label: "Chave Aleatória", value: "aleatoria" },
		{ label: "CNPJ", value: "CNPJ" },
	];

	return (
		<PageContainerDiv>
			<SidebarMenu />
			<Background>
				<RightDiv>
					<PageTitle>Perfil</PageTitle>
					<Content>
						<PacientInfo>
							<InfoBlock>
								<InfoTitle>Nome:</InfoTitle>
								{editMode ? (
									<InputEdit type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
								) : (
									<Info>{dados.nome}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Email:</InfoTitle>
								{editMode ? (
									<InputEdit
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								) : (
									<Info>{dados.email}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>CPF:</InfoTitle>
								{editMode ? (
									<InputEdit type="text" value={cpf} onChange={(e) => setCpf(e.target.value)} />
								) : (
									<MissingInfo>
										{dados.medico?.cpf ? <Info>{dados.medico?.cpf}</Info> : "Adicione seu CPF"}
									</MissingInfo>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>CNPJ:</InfoTitle>
								{editMode ? (
									<InputEdit type="text" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
								) : (
									<MissingInfo>
										{dados.medico?.cnpj ? <Info>{dados.medico?.cnpj}</Info> : "Adicione seu CNPJ"}
									</MissingInfo>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Especialidades</InfoTitle>
								{editMode ? (
									<CreatableSelect
										options={formattedList}
										isMulti
										id="Especialidades"
										name="Especialidades"
										required
										defaultValue={Object.values(dados.medico?.especialidades).map((value, key) => {
											return { label: value.nome, value: value.nome };
										})}
										onChange={(e) =>
											setEspecialidades(
												e.map((value, key) => {
													return { nome: value.value };
												})
											)
										}
										styles={customStyles}
										placeholder={"Selecione as especialidades"}
									/>
								) : (
									<Info>{dados.medico?.especialidades.map((obj) => obj.nome, ",")}</Info>
								)}
							</InfoBlock>{" "}
							<InfoBlock>
								{editMode ? (
									<>
										<InfoTitle>Valor da consulta:</InfoTitle>
										<InputEdit
											type="text"
											defaultValue={dados.medico?.creditos_consulta}
											onChange={(e) => setCreditosConsulta(e.target.value)}
										/>
									</>
								) : (
									""
								)}
							</InfoBlock>
						</PacientInfo>
						<PacientInfo>
							{user.tipo !== "clinica" && (
								<>
									<InfoBlock>
										<InfoTitle>Twitter:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={twitterUrl}
												onChange={(e) => setTwitterUrl(e.target.value)}
											/>
										) : (
											<MissingInfo>
												{twitterUrl ? <Info>{twitterUrl}</Info> : "Edite a URL do seu Twitter"}
											</MissingInfo>
										)}
									</InfoBlock>
									<InfoBlock>
										<InfoTitle>Instagram:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={instagramUrl}
												onChange={(e) => setInstagramUrl(e.target.value)}
											/>
										) : (
											<a href={instagramUrl} target="_blank" rel="noopener noreferrer">
												<MissingInfo>
													{instagramUrl ? (
														<Info>{instagramUrl}</Info>
													) : (
														"Edite a URL do seu Instagram"
													)}
												</MissingInfo>
											</a>
										)}
									</InfoBlock>
									<InfoBlock>
										<InfoTitle>Facebook:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={facebookUrl}
												onChange={(e) => setFacebookUrl(e.target.value)}
											/>
										) : (
											<a href={facebookUrl} target="_blank" rel="noopener noreferrer">
												<MissingInfo>
													{facebookUrl ? <Info>{facebookUrl}</Info> : "Edite a URL do seu Facebook"}
												</MissingInfo>
											</a>
										)}
									</InfoBlock>
								</>
							)}
							<InfoBlock>
								<InfoTitle>Chave PIX:</InfoTitle>
								{editMode ? (
									<InputEdit
										type="chavepix"
										value={chavepix}
										onChange={(e) => setChavepix(e.target.value)}
									/>
								) : (
									<Info>{dados.dados_bancarios?.chave_pix}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Tipo de chave</InfoTitle>
								{editMode ? (
									<CreatableSelect
										id="tipochave"
										name={"tipochave"}
										options={chavesList}
										onChange={(e) => setTipochave(e.value)}
										styles={customStyles1}
										defaultValue={Object.values(chavesList).filter((element) =>
											element.value.includes(dados.dados_bancarios?.tipo_chave)
										)}
									/>
								) : (
									<Info>
										{Object.values(chavesList)
											.filter((element) =>
												element.value.includes(dados.dados_bancarios?.tipo_chave)
											)
											.map((obj) => obj.label)}
									</Info>
								)}
							</InfoBlock>
						</PacientInfo>
						<PacientInfo>
							{editMode ? (
								<InfoBlock>
									<InfoTitle>CEP</InfoTitle>
									<InputEdit type="text" onChange={(e) => handleCepBlur(e)} />
								</InfoBlock>
							) : (
								""
							)}

							<InfoBlock>
								<InfoTitle>Endereço</InfoTitle>
								{editMode ? (
									<InputEdit
										readOnly
										type="text"
										value={endereco}
										onChange={(e) => setEndereco(e.target.value)}
									/>
								) : (
									<Info>{dados.medico?.endereco}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Bairro</InfoTitle>
								{editMode ? (
									<InputEdit
										readOnly
										type="text"
										value={bairro}
										onChange={(e) => setBairro(e.target.value)}
									/>
								) : (
									<Info>{dados.bairro?.nome_bairro}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Cidade</InfoTitle>
								{editMode ? (
									<InputEdit
										readOnly
										type="text"
										value={cidade}
										onChange={(e) => setCidade(e.target.value)}
									/>
								) : (
									<Info>{dados.cidade?.nome_cidade}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Estado</InfoTitle>
								{editMode ? (
									<InputEdit
										readOnly
										type="text"
										value={estado}
										onChange={(e) => setEstado(e.target.value)}
									/>
								) : (
									<Info>{dados.estado?.nome_estado}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								{editMode ? (
									""
								) : (
									<>
										<InfoTitle>Valor da consulta:</InfoTitle>
										<Info>{dados.medico?.creditos_consulta}</Info>
									</>
								)}
							</InfoBlock>
						</PacientInfo>
					</Content>
					{editMode ? (
						<Redefinir>
							<MainButton disabled={loadingEditar} onClick={handleEdit}>
								{!loadingEditar ?
								"Salvar" :
								<ReactLoading
								type="spin"
								color="#ffffff"
								height={"30px"}
								width={"30px"}
								/>
								}
							</MainButton>
							<MainButton onClick={() => setEditMode(false)}>Cancelar</MainButton>
						</Redefinir>
					) : (
						<Redefinir>
							<MainButton onClick={() => setEditMode(true)}>Editar Perfil</MainButton>
							<Link to="/redefinicao-de-senha">
								<MainButton>Redefinir senha</MainButton>
							</Link>
						</Redefinir>
					)}
				</RightDiv>
			</Background>
		</PageContainerDiv>
	);
}

export default Perfil;
